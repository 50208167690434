import React from "react";
import NoResultsIcon from "../../images/no-results-icon.svg";

const NoResults = () => {
  return (
    <div className={"text-center"}>
      <img src={NoResultsIcon} alt="No Results Found" width={108} />
      <h2>No Results Found</h2>
      <p>
        <em>
          Try to adjust your search or filter to find what you are looking for
        </em>
      </p>
    </div>
  );
};

export default NoResults;
