import React, { useState, useEffect } from "react";
import Layout from "../layout";
import * as styles from "./search.module.scss";
import { graphql, navigate, useStaticQuery } from "gatsby";
import Results from "../components/Search/Results";
import NoResults from "../components/Search/NoResults";
import get from "lodash/get";

export const Search = ({ location }) => {
  const data = useStaticQuery(graphql`
    {
      allPrismicPracticeArea(sort: { order: ASC, fields: data___order }) {
        nodes {
          uid
          dataRaw
          data {
            title
          }
        }
      }
    }
  `);

  const practiceAreas = get(data, "allPrismicPracticeArea.nodes", []);

  const [searchString, setSearchString] = useState(location.search || "");

  const [progress, setProgress] = useState(false);
  const [results, setResults] = useState([]);

  const setSearch = async (value = "") => {
    setSearchString(value);
    await navigate(`/search?s=${value}`);
  };

  const search = (searchValue = searchString || "") => {
    setProgress(true);
    const tempResults = [];
    const practiceResults = practiceAreas.filter((item) => {
      const str = JSON.stringify(item.dataRaw).toLowerCase();
      return str && str.search(searchValue.toLowerCase()) > -1;
    });
    if (practiceResults) {
      for (let i = 0; i < practiceResults.length; i++) {
        tempResults.push({
          label: practiceResults[i].data.title,
          link: `/practice-areas/${practiceResults[i].uid}`,
        });
      }
    }
    setResults(tempResults);
    setProgress(false);
  };

  const handleSearch = async (e) => {
    if (e.key.toLowerCase() === "enter" && searchString) {
      search();
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (location.search) {
      setSearchString(params.get("s"));
    }
    if (!location.search) return;
    search(params.get("s"));
  }, []);
  return (
    <Layout>
      <div className={styles.search_page_wrapper}>
        <div className="container pt-5">
          <div className="row justify-content-center">
            <div className="col-xl-8">
              <h1 className={"text-center mb-4"}>Search</h1>

              <div className="d-flex align-items-center justify-content-center mb-5">
                <input
                  type="text"
                  className="form-control"
                  value={searchString}
                  onKeyDown={handleSearch}
                  onChange={async (e) => {
                    setResults([]);
                    setProgress(true);
                    await setSearch(e.target.value);
                  }}
                />
                <button
                  className={"btn btn-primary ms-3"}
                  onClick={() => search()}
                >
                  Search
                </button>
              </div>
            </div>

            <div className="col-xl-10">
              <div className={styles.body_wrapper}>
                <div className="w-100 py-5" />

                {progress && <div className="w-100 py-5" />}

                {!progress && results.length === 0 && <NoResults />}
                {!progress && results.length > 0 && (
                  <Results results={results} />
                )}

                <div className="w-100 py-5" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Search;
