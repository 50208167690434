import React from "react";
import * as styles from "../../pages/search.module.scss";
import { Link } from "gatsby";

const Results = ({ results = [] }) => {
  return (
    <div>
      <ul className={styles.results_list}>
        {results.map(({ link, label }, idx) => (
          <li key={idx}>
            <Link to={link}>{label}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Results;
